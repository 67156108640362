import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import * as analyticService from '../../service/analytic.service';

import './Terms.scss';

const { Title, Paragraph, Text } = Typography;

const companyName = 'Heytech Sdn Bhd';
const ssmId = '202301002256 (1496175-K)';

const companyComp = <Text strong>{companyName}</Text>;

const TermsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    analyticService.trackPage('terms');
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <Div className='page-terms'>
      <Menu />
      <Helmet>
        <title>Terms of Service - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <section className='statement'>
        <Title level={2}>Terms of Service</Title>
        <Title level={3}>1. Introduction</Title>

        <Paragraph>
          These are the terms of service which apply to your use of:
        </Paragraph>

        <Paragraph>
          the HEYCAST.ME website and services; and all other websites in The{' '}
          {companyComp} <Text strong>[Registration No: {ssmId}]</Text> and the
          related services.
        </Paragraph>

        <Paragraph>
          These Terms of Service (&quot;Terms&quot;) govern your access to and
          use of the websites we own or control, including but not limited to:
          heycast.me, along with our related applications, networks, mobile
          application(s), and other services provided by us (collectively, the
          &quot;Sites&quot; and the &quot;Service&quot;). The Services include
          the provision of information and materials, and tools to enable you to
          share information and materials, network and communicate with us and
          other users. The term &quot;content&quot; is used in these terms to
          refer to such information, materials and tools. These Terms of Service
          are a legally binding contract between you and {companyComp} regarding
          your use, including the rules for your use of the Service.
        </Paragraph>

        <Paragraph>
          The terms apply between you, the user of our Sites and Services, and
          The {companyComp}, a company registered in Malaysia (also referred to
          as &quot;we&quot;, &quot;our&quot; or &quot;us&quot;).
        </Paragraph>

        <Paragraph>
          By using HeyCast.me, you are agreeing to be bound by the following
          terms. If you are not eligible or you do not agree to these terms,
          please do not access our website or use any of our products or
          services.
        </Paragraph>

        <Paragraph>
          If you use any Site or Service as an employee or representative of
          another legal entity, you accept these terms on behalf of yourself and
          the legal entity, and confirm that you have authority to do so.
        </Paragraph>

        <Paragraph>
          {companyComp} is a Talent Listing Service. {companyComp} provides a
          range of content, hosting, communication and networking services,
          including content, features, functionalities and other services to
          allow Talent (as defined below) to connect managers, casting
          directors, and other professionals rendering professional services in
          motion picture, theatrical, radio, television and other entertainment
          enterprises may be offered on our websites, mobile websites, apps, or
          from other services, products, tools, offers or communications
          provided by our Sites and Services. {companyComp} IS NOT A TALENT
          AGENCY AND THIS IS NOT A TALENT AGENCY CONTRACT.
        </Paragraph>

        <Paragraph>
          PART A contains terms which apply to all users of our Sites and
          Services.
        </Paragraph>

        <Paragraph>
          PART B contains specific additional terms which apply to you if you
          are:
        </Paragraph>

        <ul>
          <li>a talent (including acting talents, presenters and models)</li>
          <li>an employer (including casting professionals and filmmakers)</li>
          <li>
            a professional talent representative for talents (including talent
            agency or management company)
          </li>
          <li>an advertiser of services or products</li>
        </ul>

        <Paragraph>
          Some Sites may contain additional terms relevant to the specific
          Service which you may use. These shall be incorporated into these
          terms. We also suggest you refer to our privacy notice and other
          policies published on our Sites, which provide further information on
          specific aspects of our Services.
        </Paragraph>

        <Paragraph>
          Please contact us if you have any queries about these terms or their
          effect.
        </Paragraph>

        <Title level={2}>Part A – Terms Applicable To All Users</Title>
        <Title level={3}>2. How To Access Our Services</Title>
        <Title level={4}>2.1 Types of users and access rights</Title>

        <ul>
          <li>
            Visitors: Some information on the Sites can be viewed by all
            visitors to our Site, without a registration.
          </li>
          <li>
            Registration and profiles: If you wish to access specific Services,
            you must register and set up a profile. See Part B for terms
            relating to the different profiles.
          </li>
          <li>
            Details of any required payment and any additional terms will appear
            on the relevant Site.
          </li>
        </ul>

        <Paragraph>
          We aim to process applications for registration within two working
          days of receipt, but we do not guarantee access to the relevant Site
          or Service within any particular timescale. We retain the right to
          refuse access to or use of our Sites or Services to any person for any
          reason at our reasonable discretion.
        </Paragraph>

        <Title level={4}>2.2 Eligibility</Title>

        <Paragraph>
          You must meet certain criteria in order to access certain Sites and
          Services. These criteria will be specified on the relevant Sites.
        </Paragraph>

        <Paragraph>
          In particular, the Sites and Services are not intended for users of
          under 18 years of age, and such users are not permitted to register.
          However, the parent, guardian or agent of a candidate under the age of
          18 may use the Sites and Services on behalf of that candidate
          (including registering and submitting a profile), provided that the
          parent, guardian or agent complies and procures that the candidate
          complies with these terms.
        </Paragraph>

        <Paragraph>
          By registering and operating a profile on behalf of a minor, you
          acknowledge that some parts of Sites and Services may contain material
          that is inappropriate for minors, and you agree to supervise all
          activity that occurs on the minor&apos;s profile.
        </Paragraph>

        <Paragraph>
          If you register on behalf of a minor, you must still comply, and must
          ensure the minor complies, with these Terms of Use and provide the
          required consents in relation to these Terms of Use and Privacy Policy
          (on behalf of the minor). Any breach of these Terms of Use by the
          minor is deemed to be a breach by you. You must ensure that any access
          by the minor to Sites and Services or to communications made via Sites
          and Services is fully supervised by you. You or a minor registered by
          you are not permitted to upload images of an adult or inappropriate
          nature. Minors may not advertise casting calls or jobs. From time to
          time, Sites and Services may advise you of other policies or
          conditions that apply to the profiles of minors. You must comply with
          those policies or conditions if you are to continue using Sites and
          Services.
        </Paragraph>

        <Paragraph>
          In order to use our Sites and Services, you only need one account. You
          are not permitted to register as a User under multiple identities,
          personas, or aliases (whether they are false or not).
        </Paragraph>

        <Paragraph>
          If you are an entity, organization, or company, the individual
          accepting this Agreement on your behalf represents and warrants that
          they have authority to bind you to this Agreement.
        </Paragraph>

        <Title level={4}>2.3 Account restrictions</Title>

        <Paragraph>You may not use our Sites and Services if:</Paragraph>

        <ul>
          <li>
            You have ever been convicted of a serious, violent, or sexually
            related criminal offence.
          </li>
          <li>
            You have previously been banned from using our Sites and Services,
            or are associated with someone who is banned from using our
            Services.
          </li>
          <li>
            You are directly or indirectly involved in the operation of an
            entity that competes with our Sites and Services.
          </li>
          <li>
            You are prohibited from receiving our products, services, or
            software under applicable laws.
          </li>
          <li>You are associated with the adult industry.</li>
        </ul>

        <Paragraph strong>
          HEYCAST.ME reserves the right to refuse or cancel any account without
          explanation.
        </Paragraph>

        <Title level={3}>3. Content And Services Accessed By You</Title>
        <Title level={4}>3.1 Access to the Sites and Services</Title>

        <Paragraph>
          You are responsible for making all arrangements and payments necessary
          for you to have access to our Sites and Services, including internet
          connections, computer equipment and software. We are not responsible
          for:
        </Paragraph>

        <ul>
          <li>
            the availability of the internet or any communications network; nor
          </li>
          <li>
            any malfunctions, errors or other damage in or to connections,
            equipment or software that may occur in relation to your use of the
            Sites or Services.
          </li>
        </ul>

        <Paragraph>
          You should keep your passwords confidential; any use of the Sites and
          Service using your login details, including email address and password
          (other than by our Site administrators) will be your responsibility.
        </Paragraph>

        <Title level={4}>3.2. Intended use of Sites and Services</Title>

        <Paragraph>
          Our Services and content are provided for your information and
          convenience, and are not intended to be relied upon by you in taking
          any action or refraining from taking any action. Whilst we seek to
          ensure all users agree to these terms, we do not routinely check all
          content provided by users and third parties (eg profiles, job
          postings, advertisements and discussions in user forums) and cannot
          guarantee their accuracy or lawfulness.
        </Paragraph>

        <Paragraph>In particular:</Paragraph>

        <ul>
          <li>
            you should not rely on any content or Service being available,
            accurate or complete;
          </li>
          <li>
            you should not rely on our Services for storage or maintenance of
            your content or for important, sensitive or time-critical
            communications (eg using our messaging service); and
          </li>
          <li>
            inclusion of information, advertisements or other content relating
            to other users or third party services or products does not
            constitute any recommendation or endorsement by us of the relevant
            user, services or products.
          </li>
        </ul>

        <Paragraph>
          You may wish to take your own steps to confirm information, back-up
          your content and ensure that communications reach their intended
          recipient promptly and securely.
        </Paragraph>

        <Paragraph>In addition:</Paragraph>

        <ul>
          <li>
            any communications, contracts or other arrangements between you and
            other users or third parties are your responsibility. Whilst our
            Services may facilitate such dealings, we are not responsible for
            them. This includes any arrangements for auditions, roles, jobs,
            payment and delivery of services;
          </li>
          <li>
            our Sites may contain links to websites provided by third parties.
            We do not control and are not responsible for the availability,
            accuracy or content of third party websites; and
          </li>
          <li>
            we may transmit information about our Sites and Services using third
            party services such as Twitter or Facebook. We are not responsible
            for the availability of any third party services.
          </li>
        </ul>

        <Paragraph>
          You agree not to hold us liable for any loss or damage incurred as a
          result of:
        </Paragraph>

        <ul>
          <li>
            reliance on any content from or about other users or third parties,
            or about opportunities or services provided by them; nor
          </li>
          <li>
            any communications, contracts or dealings (or lack thereof) with
            other users or third parties.
          </li>
        </ul>

        <Paragraph>
          You agree that we may automatically update the Software and understand
          these Terms apply to any updates.
        </Paragraph>

        <Paragraph>
          You may not misuse our Services by interfering with their normal
          operations, or attempting to access them using a method other than
          through the interfaces and instructions that we provide.
        </Paragraph>

        <Title level={4}>3.3. Reporting unlawful content</Title>

        <Paragraph>
          You may report to us if any content or communication appears unlawful,
          inaccurate or otherwise inappropriate, or if you have a negative
          experience with any other user or third party in relation to our Sites
          or Services. We may, at our absolute discretion, investigate the
          matter and take such steps as we consider appropriate in the
          circumstances.
        </Paragraph>

        <Title level={4}>
          3.4. Intellectual property and your use of content
        </Title>

        <Paragraph>
          The contents of our Sites and Services (including information,
          trademarks, directories, text, images, videos, sounds and underlying
          software) are protected by intellectual property rights of us and our
          licensors. We grant you a non-exclusive and revocable licence to view
          and use such content for the purpose of enjoying the benefit of the
          Services.
        </Paragraph>

        <Paragraph>
          You may not copy, modify, distribute or commercially exploit any
          content (other than content provided by you) in any form or in any
          media, except that you may retrieve and display content on your
          computer and print and/or store one copy of individual pages for your
          internal, personal use.
        </Paragraph>

        <Paragraph>See also your obligations under clause 5.2 below.</Paragraph>

        <Title level={3}>4. Content Provided By You</Title>
        <Title level={4}>4.1. Accuracy and lawfulness</Title>

        <Paragraph>
          The quality of the Services provided to you and other users depends on
          you providing accurate, complete and lawful content. You acknowledge
          and agree that you:
        </Paragraph>

        <ul>
          <li>
            have provided and will provide accurate and complete content in your
            registration and profile(s) (and otherwise using our Sites or
            Services) and will keep your content updated as appropriate; and
          </li>
          <li>
            have obtained and will obtain all rights, licences and approvals
            required in any country for the use (by you, us and our other users)
            of any content you provide in relation to the Sites and the
            Services.
          </li>
        </ul>
        <Paragraph>See also your obligations under clause 5.3 below.</Paragraph>

        <Title level={4}>
          4.2. Intellectual property and our use of content
        </Title>

        <Paragraph>
          You agree that we may use the content which you provide, and, where
          relevant, trade marks relating to your trade or business, for the
          purposes of operating and maintaining the Site and the Services. You
          acknowledge that your content may be published and made available to
          other users of our Sites and Services, and modified to make it
          suitable for our Sites. We may also use your content for the purposes
          set out in clause 8 below.
        </Paragraph>

        <Paragraph>
          You grant and agree to grant us a perpetual and irrevocable worldwide
          licence to use your content for these purposes (including the right to
          sub-license to our service providers, and to other users in accordance
          with clause 3.4 above).
        </Paragraph>

        <Paragraph>
          We are under no obligation to edit or control User Content that you or
          other users post or publish, and will not be in any way responsible or
          liable for User Content. We may, however, at any time and without
          prior notice, screen, remove, edit, or block any User Content that in
          our sole judgment violates this Agreement or is otherwise
          objectionable. You understand that when using the Sites and Service
          you will be exposed to User Content from a variety of sources and
          acknowledge that User Content may be inaccurate, offensive, indecent,
          or objectionable. You agree to waive, and do waive, any legal or
          equitable right or remedy you have or may have against us with respect
          to User Content. If notified by a user or content owner that User
          Content allegedly does not conform to this Agreement, we may
          investigate the allegation and determine in our sole discretion
          whether to remove the User Content, which we reserve the right to do
          at any time and without notice. We do not permit copyright-infringing
          activities on the Sites and Service.
        </Paragraph>

        <Paragraph>
          Whilst we seek to ensure all users agree to these terms, we are not
          responsible for the use or misuse of your content by other users or
          third parties and you agree not to hold us liable for any loss or
          damage incurred as a result of such use or misuse. We shall, however,
          at your expense, reasonably co-operate with you in enforcing our
          contractual terms against other users who have infringed your rights.
        </Paragraph>

        <Paragraph>
          You agree we may use cookies to track your actions around the site as
          specified in our privacy policy.
        </Paragraph>

        <Paragraph>Please also see:</Paragraph>

        <ul>
          <li>
            PART B below in relation to the use of specific user content; and
          </li>
          <li>
            our privacy notice for information on how your personal data will be
            used by us
          </li>
        </ul>

        <Title level={4}>4.3. Further information we may need</Title>

        <Paragraph>
          Whilst we do not routinely check or monitor all content provided by
          users (see clause 3.2 above), we may do so at our discretion, for the
          purposes outlined at clause 8 below. Upon request, you will provide us
          with evidence that you are complying with the above requirements,
          including:
        </Paragraph>

        <ul>
          <li>
            evidence of your ownership of and/or rights to intellectual property
            subsisting in relation to content;
          </li>
          <li>
            evidence of your rights to use any personal data or information
            relating to third parties; and
          </li>
          <li>details of any relevant licensors, licences and approvals.</li>
        </ul>

        <Paragraph>
          You also agree to provide us with required details so that can credit
          the author/creator of any image, sound, video or other content.
        </Paragraph>

        <Title level={3}>5. Your Conduct In Using Our Sites And Services</Title>
        <Title level={4}>5.1. Restrictions on commercial use</Title>

        <Paragraph>
          You must use the Site and the Services only for purposes consistent
          with your registration, profile(s) and subscriptions. Unless you
          register and create a profile with us as a service provider or
          subscribe for our advertising Service (and pay the applicable fees)
          (see PART B below), you may not use the Site or any Service (including
          any email or communication service) to advertise, solicit or promote
          any products or services.
        </Paragraph>

        <Paragraph>
          We may charge you a fee of RM200 for each act in breach of your
          obligations under this clause 5.1, representing our advertising fee,
          wasted time and loss of custom/reputation arising as a result of your
          breach.
        </Paragraph>

        <Title level={4}>5.2. Prohibition on unlawful use of content</Title>

        <Paragraph>
          You may not make any unlawful or unauthorised use of any content,
          including:
        </Paragraph>

        <ul>
          <li>
            distributing to third parties any audition or casting information
            obtained on a Site;
          </li>
          <li>
            without prejudice to clause 3.4 above, unauthorised copying or use
            of content protected by intellectual property rights;
          </li>
          <li>
            unauthorised distribution or disclosure of confidential, personal or
            sensitive information (including user profiles);
          </li>
          <li>
            use of any content (other than content provided by you) in any
            manner which may compete with our business; and
          </li>
          <li>
            other unauthorised reproduction or use of content for commercial
            purposes.
          </li>
        </ul>

        <Title level={4}>5.3. Prohibition on providing unlawful content</Title>

        <Paragraph>
          You may not upload, provide or transmit any unlawful, unauthorised or
          offensive content, including:
        </Paragraph>

        <ul>
          <li>
            content which is discriminatory, defamatory, derogatory, indecent,
            obscene or abusive;
          </li>
          <li>unauthorised marketing materials or communications;</li>
          <li>content containing personal political or religious views;</li>
          <li>
            content whose publication or use on the Site or in relation to the
            Services would be in breach of copyright or other intellectual
            property rights, data protection or confidentiality obligations or
            any rights of privacy; and
          </li>
          <li>inaccurate content about yourself or another party.</li>
        </ul>

        <Title level={4}>5.4. Prohibition on other unlawful acts</Title>

        <Paragraph>
          In using our Sites and Services, you must comply with all applicable
          laws. You may not make any unlawful or unauthorised use of our or our
          service providers’ computers, networks, communications systems, or
          other equipment or software, including:
        </Paragraph>

        <ul>
          <li>attempting to gain unauthorised access;</li>
          <li>
            introducing or attempting to introduce any computer virus or other
            malware or cause any &quot;denial of service&quot; attack;
          </li>
          <li>
            modifying, reverse engineering or decompiling any part of the
            underlying software for the Site;
          </li>
          <li>stalking, harassing or threatening any person;</li>
          <li>
            sending unlawful, unauthorised or offensive communications
            (including spam, identical bulk emails, unauthorised marketing
            communications or any content in breach of clause 4 above);
          </li>
          <li>
            contacting other users for reasons unrelated to or inappropriate for
            the Site or Service;
          </li>
          <li>
            otherwise restricting or inhibiting any other user from using and
            enjoying any Site or Service; and
          </li>
          <li>
            misrepresenting yourself or another party or the activities of you
            or another party, or committing or assisting others to commit any
            criminal or fraudulent act.
          </li>
        </ul>

        <Title level={3}>6. Linking To Or From Our Sites</Title>

        <Paragraph>You may:</Paragraph>

        <ul>
          <li>
            include links to our Sites on your website or other materials;
            and/or
          </li>
          <li>
            include links to your website within your profile(s) on our Sites,
          </li>
        </ul>

        <Paragraph>
          provided you do so in a way that is fair and lawful and does not
          damage our reputation or take unfair advantage of it. You must not
          establish a link in such a way as to suggest any form of association,
          recommendation or endorsement by us where none exists.
        </Paragraph>

        <Title level={3}>7. Charges For Platform Services Fee</Title>

        <Paragraph>
          Details of applicable charges are on the Sites relating to the
          relevant Services (or can be provided to you by us upon request).
          Unless otherwise agreed by us, payments must be made by credit or
          debit card in the currency specified by us. You are responsible for
          all charges associated with your use of Services using your login
          details (including email address, phone number and password).
        </Paragraph>

        <Paragraph>
          You must pay any applicable taxes (including value added tax) in
          addition to any service fee for the relevant Services. We may charge
          interest on overdue payments.
        </Paragraph>

        <Title level={3}>
          8. Monitoring Your Use Of The Sites And Services
        </Title>

        <Paragraph>
          We may monitor, access, use and disclose details of your use of the
          Sites and Services (including your content) for the purposes of
          checking compliance with these terms, quality control, site
          administration and maintenance, risk assessments and investigations,
          compliance with legal obligations, or protection and enforcement our
          legal rights and those of our users. See also our privacy notice.
        </Paragraph>

        <Title level={3}>9. Consequences Of Breaching These Terms</Title>

        <Paragraph>
          If you breach or we reasonably suspect that you are in breach of any
          of your obligations under these terms, we may immediately and without
          notice suspend your access to and use of the Sites and Services, or
          any part of the Sites and Services or content and/or (without
          prejudice to clause 11) remove any of your content. We may also
          terminate your access to the Sites and Services in accordance with
          clause 10 below. You will not be entitled to any compensation for any
          period of suspension other than in accordance with our refunds policy.
        </Paragraph>

        <Paragraph>
          You agree to indemnify us and our representatives against all claims,
          loss or damage that we or they may incur as a result of a breach by
          you of your obligations under these terms, including any claims,
          losses or damage arising from inaccurate or unlawful content which you
          upload, provide or transmit.
        </Paragraph>

        <Title level={3}>
          10. Termination Of Your Access To Sites And Services
        </Title>

        <Title level={4}>10.1. Your and our rights to terminate</Title>

        <Paragraph>
          You may terminate your use of any Sites and Services and request
          removal of your profile(s) at any time. As a registered user, you
          should give notice to us by email or in writing at least two weeks in
          advance of your proposed date for such termination and removal. This
          is to allow us time to remove your profile from the relevant Sites and
          disable your access rights and login details as required (although we
          shall use reasonable endeavours to do so sooner, if requested). Any
          such notice will take effect when received by us.
        </Paragraph>

        <Paragraph>
          We are under no obligation to edit or control User Content that you or
          other users post or publish, and will not be in any way responsible or
          liable for User Content. We may, however, at any time and without
          prior notice, screen, remove, edit, or block any User Content that in
          our sole judgment violates this Agreement or is otherwise
          objectionable. You understand that when using the Sites and Service
          you will be exposed to User Content from a variety of sources and
          acknowledge that User Content may be inaccurate, offensive, indecent,
          or objectionable. You agree to waive, and do waive, any legal or
          equitable right or remedy you have or may have against us with respect
          to User Content. If notified by a user or content owner that User
          Content allegedly does not conform to this Agreement, we may
          investigate the allegation and determine in our sole discretion
          whether to remove the User Content, which we reserve the right to do
          at any time and without notice. We do not permit copyright-infringing
          activities on the Sites and Service.
        </Paragraph>

        <Paragraph>
          We may withdraw any Site or Service or terminate your access to and
          use of any Site or Service at any time and for any reason at our
          reasonable discretion. If you are a registered user to any Service,
          and unless such termination is due to your material or persistent
          breach of these terms, we will use reasonable endeavours to give
          notice to you of any such termination.
        </Paragraph>

        <Title level={4}>10.2. Consequences of termination</Title>

        <Paragraph>
          If you have made advance payments to us for any Service relating to
          any period after the date of termination, and unless such termination
          is due to your material or persistent breach of these terms, you may
          be entitled to a refund in accordance with our refunds policy. You
          will not be entitled to any other compensation for any such
          termination.
        </Paragraph>

        <Paragraph>
          Following termination of your access to or use of any Sites and
          Services for any reason, we may remove your profile(s) from the Site
          (if not already removed) and disable your login details, including
          username(s) and passwords. We may however retain your profile and
          other personal details on our internal systems for record keeping
          purposes. Please see our privacy notice for further details. Other
          content you have provided may also remain on our Sites.
        </Paragraph>

        <Paragraph>
          All provisions of these terms which by their nature are intended to
          continue shall survive termination, including terms relating to
          exclusions and limitations of liability, intellectual property and
          on-going use of your content.
        </Paragraph>

        <Paragraph>
          Termination of your access to the Sites or Services or of these terms
          shall not affect accrued rights and liabilities of you or us up to the
          date of termination, including our rights to payments due from you.
        </Paragraph>

        <Title level={3}>11. Changes To Sites, Services And These Terms</Title>

        <Paragraph>
          We may, at any time and at our absolute discretion, make temporary or
          permanent changes, additions or deletions (together,
          &quot;changes&quot;) to or from:
        </Paragraph>

        <ul>
          <li>
            the Sites and the Services (including to your or other content);
            and/or
          </li>
          <li>these terms.</li>
        </ul>

        <Paragraph>
          We shall use reasonable endeavours to notify our registered users of
          material changes. It is also your responsibility refer to these terms
          on a regular basis.
        </Paragraph>

        <Paragraph>
          By continuing to use the Sites and/or the Services after such changes,
          you agree to such changes. If you are not happy with any changes, you
          may terminate your use of the Sites and Services in accordance with
          clause 10.
        </Paragraph>

        <Paragraph>
          The Sites and the Services (and any content) may also be unavailable
          for occasional periods for repair, maintenance or upgrading, or
          interruptions in the network or communications services provided to
          us. You agree not to hold us liable for any loss or damage caused by
          such occasional periods of unavailability.
        </Paragraph>

        <Title level={3}>12. Our Liability To You</Title>
        <Title level={4}>
          12.1. Liability for fraud, death and personal injury
        </Title>

        <Paragraph>
          Nothing in these terms shall exclude or limit our liability for fraud,
          or for death or personal injury caused by negligence or other
          liability which cannot lawfully be excluded.
        </Paragraph>

        <Title level={4}>12.2. Limitations on our liability</Title>

        <Paragraph>
          The provisions of this clause 12.2 are subject to clause 12.1 and
          shall have effect in addition to the other limitations of liability on
          specific issues set out in these terms.
        </Paragraph>

        <Paragraph>
          Except as set out in these terms, all conditions, warranties and
          representations applicable to the provision of the Sites and the
          Services implied by statute or common law are excluded to the maximum
          extent permitted by law.
        </Paragraph>

        <Paragraph>
          Neither we nor our representatives shall be liable to you for:
        </Paragraph>

        <ol type='a'>
          <li>any direct loss or damage; nor</li>
          <li>any consequential or indirect loss or damage,</li>
        </ol>

        <Paragraph>
          arising in relation to the use or inability to use our Site or our
          Services (including any content), including any loss of business, job,
          job opportunity, customers, revenue, profit, contracts, data, software
          or reputation, or wasted time, whether caused by tort (including
          negligence), breach of contract or otherwise, and even if foreseeable.
        </Paragraph>

        <Paragraph>
          Without prejudice to the other limitations of liability in these terms
          (including this clause 12), our total liability to you arising in
          relation to your use of the Site and/or the Services in any 12 month
          period shall be limited to amounts paid by you for the relevant
          Service during that period.
        </Paragraph>

        <Title level={4}>12.3 Indemnity</Title>

        <Paragraph>
          You agree to release, indemnify and keep indemnified us from and
          against all actions, claims, costs (including legal costs and
          expenses) losses, proceedings, damages, liabilities, or demands
          suffered or incurred by us to any person arising out of or in
          connection with your use of our the Sites and the Services or your
          breach of any term of your agreement with us.
        </Paragraph>

        <Title level={3}>13. General Terms</Title>

        <Paragraph>
          These terms are governed by the laws of Malaysia, and the courts of
          Malaysia shall have exclusive jurisdiction to hear any disputes
          arising from them.
        </Paragraph>

        <Paragraph>
          You agree to try to resolve any disputes you have with another User
          directly with them. We do try to offer help and information where we
          can, but we&apos;re not a party in any agreement between you and
          another Member.
        </Paragraph>

        <Paragraph>
          We shall not be responsible for any failure or delay in performance of
          the Services or any of our obligations under these terms due to a
          cause beyond our reasonable control.
        </Paragraph>

        <Paragraph>
          The headings in these terms are for ease of reference only.
        </Paragraph>

        <Paragraph>
          Unless the context requires otherwise, in these terms and our privacy
          notice, the term &quot;including&quot; shall be interpreted to mean
          &quot;including but not limited to&quot;.
        </Paragraph>

        <Paragraph>
          The provision of the Services shall not create any relationship of
          agent and principal between you and us and neither you nor we have any
          authority or power to bind or to contract in the name of the other
          party.
        </Paragraph>

        <Paragraph>
          No provision of these terms is intended to be enforceable by any
          person other than you or us.
        </Paragraph>

        <Paragraph>
          Failure by us or you to exercise any right or remedy under these terms
          does not constitute a waiver of that right or remedy.
        </Paragraph>

        <Paragraph>
          You may not transfer, assign or sub-contract your rights or
          obligations under these terms to any third party without our prior
          written consent.
        </Paragraph>

        <Paragraph>We may, without your consent:</Paragraph>

        <ul>
          <li>
            sub-contract any of our obligations under this agreement to any
            third party; and
          </li>
          <li>
            upon notice to you (if you are a registered user) or without notice
            to you (if you are a visitor), transfer or assign all or any of our
            rights and/or obligations under these terms to any third party.
          </li>
        </ul>

        <Paragraph>
          If any provision of these terms is found by any court or legal
          authority to be invalid, unenforceable or illegal, the other
          provisions shall remain in force and, to the extent possible, the
          provision shall be modified to ensure it is valid, enforceable and
          legal, whilst maintaining or giving effect to its commercial
          intention.
        </Paragraph>
        <Paragraph>
          These terms, including any additional provisions incorporated by
          reference, constitute the entire agreement between you and us with
          respect to your access to and use of the Sites and Services and
          supersede all prior agreements, negotiations and discussions between
          you and us relating to the subject matter.
        </Paragraph>

        <Title level={2}>
          Part B – Additional Terms Applicable To Specific Users
        </Title>
        <Title level={3}>14. Talents</Title>
        <Title level={4}>14.1. No guarantee of jobs</Title>

        <Paragraph>
          Without prejudice to clause 3.2, you acknowledge that the decision to
          offer you jobs or appointments rest with the casting agents and other
          employers and the availability of such jobs is affected by the state
          of the industry as a whole. We cannot guarantee you work nor that
          employers who view your profile will call you in for an audition or
          interview.
        </Paragraph>

        <Paragraph>
          It is your responsibility to ensure that any job or appointment
          offered by employers is acceptable to you. We are unable to verify or
          provide any assurance relating to the nature or duration of, or
          payment for any such job or appointment.
        </Paragraph>

        <Title level={4}>14.2. Platform Service Fee</Title>

        <Paragraph>
          By registering a profile on Heycast.me, you agree to pay a Platform
          Service Fee in accordance with the fee structure outlined below:
        </Paragraph>

        <Paragraph>
          (a) For talent fees less than RM800, a Platform Service Fee of 10% of
          the talent fee will be applied.
        </Paragraph>

        <Paragraph>
          (b) For talent fees equal to or greater than RM800, a Platform Service
          Fee of 20% of the talent fee will be applied.
        </Paragraph>

        <Paragraph>
          This Platform Service Fee helps support and maintain the Heycast.me
          platform, ensuring continued access to a robust talent pool and
          efficient talent matching services. Please note that this fee is in
          addition to the agreed-upon talent fees and will be automatically
          deducted upon confirmation of a job.
        </Paragraph>

        <Paragraph>
          The Platform Service Fee only calculated from the final talent fee
          offered and excluded all other forms of allowance entitled by Talents
          such as petrol allowance, overtime allowance or other similar
          allowances.
        </Paragraph>

        <Paragraph>
          Current information required. You must provide current, complete and
          accurate information for your Bank Account. You must promptly update
          any information that changes (such as a bank, bank account number or
          other relevant information), and you must promptly notify HEYTECH SDN
          BHD if your Bank Account is cancelled (e.g. for loss or theft) or if
          you become aware of a potential breach of security, such as the
          unauthorised disclosure or use of your user name or password. Changes
          to such information can be made by emailing hello@heycast.me If you
          fail to provide HEYTECH SDN BHD with any of this information, you
          agree that HEYTECH SDN BHD may continue transferring you for any use
          of HEYTECH SDN BHD under your Bank Account unless you have cancelled
          your account for HEYTECH SDN BHD.
        </Paragraph>

        <Paragraph>
          Service fee discrepancies. Unless you notify HEYTECH SDN BHD of any
          discrepancies within 60 days after they first appear on your payment
          statement, they will be deemed accepted by you and you release HEYTECH
          SDN BHD from all liabilities and claims of loss resulting from any
          such error or discrepancy.
        </Paragraph>

        <Paragraph>
          Fee changes. We reserve the right to increase fees or introduce new
          prices or charges at any time.
        </Paragraph>

        <Title level={4}>14.3 Terms &amp; Conditions of Payment</Title>

        <Paragraph>
          All the payments for the talent’s work will be transferred to Our
          account. After deduction of tax and services, We will transfer the
          attributable sum to talent within 10 business days from the day full
          payments have been received on Our account.
        </Paragraph>

        <Title level={3}>15. Employers</Title>

        <Paragraph>
          Casting professionals, film-makers and all other employers must ensure
          that their job postings and other content, and the terms of the job or
          role to which any posting relates, comply with all applicable laws,
          including those relating to minimum payments, non-discrimination, tax
          and employment.
        </Paragraph>

        <Paragraph>
          It remains the responsibility of the Employer to ensure that Talents
          found through HEYCAST.ME have the licences necessary for completing
          work, and that background checks are conducted where necessary.
        </Paragraph>

        <Paragraph>
          Without prejudice to clauses 4.2, 9 and 11, we may edit any job
          posting which you submit to make it appropriate for the relevant Site.
        </Paragraph>

        <Paragraph>
          Employers may not use their employer profile or Services aimed at
          employers (including any job posting, email or communication service)
          to advertise, solicit or promote any products or services. We may
          charge you a fee of RM1,000 for each breach of this requirement – see
          clause 5.1 above.
        </Paragraph>

        <Title level={4}>
          15.1 Terms &amp; Condition of Talent Fee Payment
        </Title>

        <Paragraph>
          You are agreeable that all the payments for the talent’s work will be
          transferred to the Our account. After deduction of tax and services,
          We will transfer the attributable sum to talent within 10 business
          days from the day full payments have been received on Our account.
        </Paragraph>

        <Title level={3}>
          16. Professional Talent Representative (Professional Agents)
        </Title>

        <Paragraph>
          If you use the Sites or Services as an agent for candidates, it is
          your duty to inform such candidates of the scope of the Services, and
          the relevant requirements and restrictions as set out in these terms
          (for example, those set out in clause 14 above).
        </Paragraph>

        <Paragraph>
          Each Talent Representative represents that it is legally authorized to
          represent its respective Talent in connection with the Services and
          utilize all aspects of the applicable Services on behalf of the Talent
          in accordance with these Terms, including where applicable, the
          completion of a Profile or Resume and the sharing of that information
          with third parties. A Talent Representative agrees to stop using the
          Services immediately when such authority from Talent is no longer in
          effect. We are not obligated, but we reserve the right at any time, to
          require evidence of a Talent Representative’s authority to enter into
          these Terms and act on behalf of Talent in connection with the
          Services. Each Talent Representative also represents and warrants that
          all personal information that he or she provides to us through the
          Site or otherwise is collected by such Talent Representative or on his
          or her behalf and disclosed to us in full compliance with the
          applicable Data Privacy Laws including, as required by such Data
          Privacy Laws, with the comprehensive consent from or comprehensive
          data processing information provided to the relevant individuals whose
          personal information is provide to us.
        </Paragraph>

        <Paragraph>
          Each Authorized Representative is solely responsible for their acts or
          omissions in connection with the Services, including any violation of
          these Terms and HEYTECH SDN BHD’s Privacy Policy. Each Authorized
          Representative indemnifies and holds harmless HEYTECH SDN BHD and its
          affiliates, employees, officers, directors, and representatives from
          any losses, costs, expenses, damages, fines and/or penalties including
          reasonable attorneys’ fees, arising out of or in connection with a
          breach of this representation.
        </Paragraph>

        <Paragraph>
          Talent or Talent’s Authorized Representative is responsible for
          promptly notifying HEYTECH SDN BHD when a Talent Representative has
          been terminated or changed. If Talent has no representation, HEYTECH
          SDN BHD will turn control of the Account over to Talent or Talent’s
          Authorized Representative subject to their direct acceptance of these
          Terms. If changed, HEYTECH SDN BHD will transfer the account to the
          new Talent Representative as directed by the Talent or Talent’s
          Authorized Representative. Any changes should be sent to
          hello@heycast.me.
        </Paragraph>

        <Paragraph>
          During the Account registration process, you will be prompted to
          provide personal information that allows us to know who you are, such
          as your full name, age, street address, email address and phone
          number, as well as additional optional information such as height,
          weight, appearing ethnicity, and pictures (collectively, “Registration
          Information”). By using our Sites and Services, you represent and
          warrant that all Registration Information you submit is truthful,
          accurate, current and complete and you will maintain the accuracy of
          such Registration Information by updating and revising it promptly.
        </Paragraph>

        <Paragraph>
          If you are a Talent Representative providing Registration Information
          on behalf of Talent, you warrant and represent that you have full
          authority to provide us with the Registration Information and any
          other personally identifying information of such Talent. If you are an
          Authorized Representative of Talent under the age of majority, a valid
          parent/guardian authorization may be required by law. The Services are
          not directed to children under the age of 13, and they are not
          permitted to sign up for or use the Services.
        </Paragraph>

        <Paragraph>
          You authorize HEYTECH SDN BHD to make any inquiries, either directly
          or through third parties, to validate your Registration Information.
          HEYTECH SDN BHD reserves all rights to take legal action against
          anyone who provides inaccurate, false or incomplete personal
          information or is otherwise untruthful about their identity, and to
          suspend or cancel an Account established using such information.
          Notwithstanding the foregoing, you acknowledge that HEYTECH SDN BHD
          cannot guarantee the accuracy of any information submitted by any User
          of the Services, including any identity information about any User.
        </Paragraph>

        <Title level={3}>17. Advertisers</Title>

        <Paragraph>
          In order to advertise on our Sites, you must set up a profile and
          separately apply for advertising space on our Site together with
          payment of a fee. Further details of how to apply, and where and when
          your advertisement may be displayed, are contained on the Sites where
          advertising space is available.
        </Paragraph>

        <Paragraph>
          It is your responsibility to ensure any advertisement you submit to
          our Site complies with all applicable legal requirements, advertising
          standards and codes of practice. In addition, you may not submit
          advertisements for services or products which may compete with our
          Services. Without prejudice to clauses 9 and 11, we may refuse to
          publish or remove any advert we reasonably consider to be in breach of
          these terms.
        </Paragraph>

        <Title level={3}>18. Additional General Terms</Title>
        <Title level={4}>18.1 Trademarks</Title>

        <Paragraph>
          All Trademarks appearing in the Site, Application and/or via the
          Services are the Trademark of the Company in Malaysia and/or other
          countries.
        </Paragraph>

        <Paragraph>
          All other Trademarks not owned by the Company that appear on the Site,
          Application and/or via the Services are the property of their
          respective owners.
        </Paragraph>

        <Title level={4}>18.2 Copyright</Title>

        <Paragraph>
          All contents published on the Site and the Application, such as text,
          graphics, logos, button icons, images, audio clips, video clips,
          digital downloads, data compilations, and software, are the property
          of the Company and/or its content suppliers and protected by all
          applicable copyright laws.
        </Paragraph>

        <Title level={4}>18.3 Electronic Communications</Title>

        <Paragraph>
          The Company may communicate with You by email, or by posting notices
          on the Site and/or the Application. You agree that all agreements,
          notices, disclosures and other communications that the Company
          provides to You electronically satisfy any legal requirement that such
          communications be in writing.
        </Paragraph>

        <Title level={4}>18.4 Governing Law</Title>

        <Paragraph>
          The laws of Malaysia, excluding its conflicts of law rules, shall
          govern this Terms of Use and Your use of the Site, the Application
          and/or the Services. Your use of the Application and the Site may also
          be subject to other local, state, national, or international laws.
        </Paragraph>
      </section>
      <Footer />
    </Div>
  );
};

export default TermsPage;
